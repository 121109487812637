// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Astro Clean Carpets components
import MKBox from "components/MKBox";

// Astro Clean Carpets
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import cardImg from "assets/images/upholstery-card.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
              <MKBox mb={5}>
              <DefaultInfoCard
                    icon="refresh"
                    title="Fabric Revitalization"
                    description="Revive the look and feel of your furniture with our deep fabric cleaning, removing dirt, stains, and odors."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="child_friendly"
                    title="Safe for All Fabrics"
                    description="Our gentle, yet effective cleaning solutions are safe for all types of upholstery fabric, ensuring no damage."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="timer"
                    title="Fast Service & Dry"
                    description="Enjoy minimal downtime with our quick cleaning process and fast-drying techniques."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="eco"
                    title="Eco-Friendly & Safe"
                    description="We prioritize your health and the environment by using eco-friendly and non-toxic cleaning agents."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={cardImg}
              title="Schedule Your Upholstery Cleaning"
              description="Transform and refresh your living space with our professional upholstery cleaning service. Protect your investment and enhance your home’s hygiene and appearance. Book your appointment now and experience the difference."
              action={{
                type: "external",
                color: "info",
                label: "Call Now",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
