// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Astro Clean Carpets components
import MKBox from "components/MKBox";

// Astro Clean Carpets
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import cardImg from "assets/images/tile-and-grout-card.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
              <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="clean_hands"
                    title="Deep Cleaning"
                    description="Experience a deeper level of clean with our powerful tile and grout cleaning process, removing grime and restoring shine."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="verified_user"
                    title="Seal and Protect"
                    description="After cleaning, we apply a sealant to protect your grout from future stains, ensuring long-lasting cleanliness and durability."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="speed"
                    title="Quick Drying Times"
                    description="Our efficient cleaning techniques ensure quick drying times so you can enjoy your refreshed spaces sooner."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="eco"
                    title="Eco-Friendly Products"
                    description="Safeguard your home and the planet with our use of eco-friendly cleaning products, free from harmful chemicals."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
          <CenteredBlogCard
              image={cardImg}
              title="Schedule Your Cleaning"
              description="Unlock the true potential of your tiles with our specialized tile and grout cleaning services. Say goodbye to dirt and hello to a sparkling clean home. Schedule your appointment today and witness the transformation."
              action={{
                type: "external",
                color: "info",
                label: "Call Now",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
