// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Astro Clean Carpets components
import MKBox from "components/MKBox";

// Astro Clean Carpets
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import cardImg from "assets/images/spot-and-stain-card.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
              <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="pets"
                    title="Pet Odor & Stain Removal"
                    description="Target tough pet stains and odors with our specialized cleaning. Safe and effective, ensuring your home smells fresh and is hygienic."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="verified_user"
                    title="Pet-Safe Cleaning"
                    description="Our methods are pet-friendly. We use non-toxic solutions that are safe for your furry friends and the whole family."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="air"
                    title="Air Quality Improvement"
                    description="Beyond cleaning, we enhance your indoor air quality, reducing allergens and creating a healthier environment for everyone."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="eco"
                    title="Eco-Friendly & Safe"
                    description="We prioritize your health and the planet, using eco-friendly, sustainable cleaning agents for every job."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={cardImg}
              title="Pet-Friendly Cleaning Services"
              description="Deal effectively with pet stains and odors. Our advanced cleaning solutions are designed specifically for homes with pets, ensuring a clean, safe, and fresh-smelling environment. Schedule your spot and stain removal service today and enjoy a cleaner home."
              action={{
                type: "external",
                color: "info",
                label: "Call Now",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
