// @mui material components
import Grid from "@mui/material/Grid";

// Astro Clean Carpets components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Astro Clean Carpets examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function ContactUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "internal",
            route: "/1/contact-us",
            label: "contact us",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
            <MKTypography variant="body2" color="text" mb={3} textAlign="center">
                Ready to deep clean your carpets, upholstery, or tackle those stubborn stains? 
                Fill out the form below for a free estimate. Our team is here to bring freshness 
                and cleanliness back to your home.
              </MKTypography>
              <MKBox component="form" method="post" action="https://formspree.io/f/xzbnvgva" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MKInput
                      variant="standard"
                      label="Full Name"
                      name="fullName" // Add name attribute
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required // Make this field required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      name="email" // Add name attribute
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      required // Make this field required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Phone Number"
                      name="phone" // Add name attribute
                      InputLabelProps={{ shrink: true }}
                      placeholder="123-456-7890"
                      fullWidth
                      required // Optional, based on whether you want this field to be required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Service Required"
                      name="service" // Add name attribute
                      InputLabelProps={{ shrink: true }}
                      placeholder="Carpet Cleaning, Upholstery Cleaning, Spot and Stain Removal, etc."
                      fullWidth
                      required // Make this field required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="Message"
                      name="message" // Add name attribute
                      placeholder="Provide any additional details here."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={4}
                      required // Make this field required
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
