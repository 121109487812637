/*
=========================================================
* Astro Clean Carpets - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Astro Clean Carpets pages
import ContactUs from "pages/LandingPages/ContactUs";

export default function ContactUsPage() {
  return <ContactUs />;
}
