/*
=========================================================
* Astro Clean Carpets - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Astro Clean Carpets components
import MKBox from "components/MKBox";

// Astro Clean Carpets examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Experience
                    <br />
                    Pristine Cleanliness
                  </>
                }
                description="Our exceptional cleaning services rejuvenate your carpets and tiles, providing you with a clean space."
              />
              <RotatingCardBack
                image={bgBack}
                title="contact us"
                description="Indulge in pure comfort and elevate your well-being. Experience the revitalizing touch as we breathe new l
                ife into your space with our advanced cleaning expertise."
                action={{
                  type: "internal",
                  route: "/1/contact-us",
                  label: "Request a quote",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Carpet Cleaning"
                  description="Experience the ultimate revival for your carpets. Our professional carpet cleaning services, 
                  backed by expertise and attention to detail, ensure a refreshed and spotless appearance for your beloved carpets"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Tile and Grout Cleaning"
                  description="Revive your tiles and grout with our expert touch. Witness the transformation as we bring back the 
                  shine and freshness, turning your tiled surfaces into captivating centerpieces that elevate your space."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="weekend"
                  title="Upholstery Cleaning"
                  description="Give your furniture a breath of fresh air. Let our upholstery cleaning services 
                  bring renewed vitality and cleanliness to your cherished pieces, ensuring a cozy and inviting atmosphere in your living spaces."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="pets"
                  title="Spot and Stain Removal"
                  description="Banish spots and stains with ease. Our pet-friendly spot and stain removal services ensure a flawless and 
                  pet-safe environment, so you can enjoy a clean and worry-free space with your furry companions."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
