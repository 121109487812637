// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Astro Clean Carpets components
import MKBox from "components/MKBox";

// Astro Clean Carpets
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import cardImg from "assets/images/set-up-apt-card.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="eco"
                    title="Eco-Friendly Solutions"
                    description="Our advanced cleaning methods use environmentally safe products to ensure a healthier home for you and the planet."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="autorenew"
                    title="Revitalize Your Carpets"
                    description="Bring back your carpet's vibrant colors and soft texture with our deep cleaning and revitalization process."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="schedule"
                    title="Fast & Efficient Service"
                    description="Experience minimal disruption with our efficient cleaning process, designed to get your carpets dry and ready in no time."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="remove_red_eye"
                    title="Stain Removal"
                    description="Our specialists are equipped to remove even the toughest stains, leaving your carpets looking spotless."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={cardImg}
              title="Set up an appointment"
              description="Embark on the journey to rejuvenate your carpets with Astro Clean Carpet's specialized cleaning service. Our expert team utilizes eco-friendly solutions and state-of-the-art technology to deep clean, remove stubborn stains, and revitalize your carpets, ensuring they look and feel as good as new."
              action={{
                type: "external",
                color: "info",
                label: "call now",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
